
























































import { mapState } from 'vuex'
import { get as getr, post, patch, del } from '@/services/api'
import { Reaction } from '@/types'
import { AxiosResponse } from 'axios'

import bus from '@/services/event-bus'

import BnglText from '@/components/shared/bngl-text.vue'
import ReactionForm from '@/components/shared/entities/reaction-form.vue'
import Split from '@/components/split.vue'
import Properties from '@/components/model-page/secondary-view/reaction-properties.vue'

import findUniqName from '@/tools/find-uniq-name'
import objStrSearchFilter from '@/tools/obj-str-search-filter'
import blockHeightWoPadding from '@/tools/block-height-wo-padding'

const defaultReaction = {
  id: undefined,
  name: '',
  valid: false,
  definition: '',
  kf: '',
  kr: '',
  annotation: '',
}

const searchProps = ['name', 'definition']

export default {
  name: 'reactions-component',
  components: {
    split: Split,
    properties: Properties,
    'reaction-form': ReactionForm,
  },
  data() {
    return {
      error: false,
      deleteError: false,
      reactions: [],
      searchStr: '',
      tableHeight: null,
      newReactionModalVisible: false,
      current: { ...defaultReaction },
      columns: [
        {
          title: 'Name',
          key: 'name',
          maxWidth: 180,
        },
        {
          title: 'Kf',
          render: (h, params) =>
            h(BnglText, {
              props: {
                entityType: 'parameter',
                readOnly: true,
                value: params.row.kf,
              },
            }),
          maxWidth: 180,
        },
        {
          title: 'Kr',
          render: (h, params) =>
            h(BnglText, {
              props: {
                entityType: 'parameter',
                readOnly: true,
                value: params.row.kr,
              },
            }),
          maxWidth: 180,
        },
        {
          title: 'BioNetGen definition',
          render: (h, params) =>
            h(BnglText, {
              props: {
                entityType: 'reaction',
                readOnly: true,
                value: params.row.definition,
              },
            }),
        },
      ],
    }
  },
  async created() {
    this.reactions = await this.getReactions()
  },
  mounted() {
    this.timeoutId = window.setTimeout(() => this.computeTableHeight(), 0)
    bus.$on('layoutChange', () => this.computeTableHeight())
  },
  beforeDestroy() {
    window.clearTimeout(this.timeoutId)
    bus.$off('layoutChange')
  },
  methods: {
    async getReactions() {
      const model = this.$store.state.model

      if (!model?.id) return []

      const res: AxiosResponse<Reaction[]> = await getr('reactions', {
        user_id: model?.user_id,
        model_id: model?.id,
      })

      return res.data
    },

    add() {
      this.current = {
        ...defaultReaction,
        name: findUniqName(this.reactions, 'r'),
      }
      this.showNewReactionModal()

      this.$nextTick(() => {
        this.$refs.reactionForm.refresh()
        this.$refs.reactionForm.focus()
      })
    },
    async remove() {
      const res = await del<null>(`reactions/${this.current.id}`)
      if (!res) {
        this.deleteError = true
        return
      }

      this.deleteError = false

      this.current = { ...defaultReaction }
      this.reactions = await this.getReactions()
    },
    showNewReactionModal() {
      this.newReactionModalVisible = true
    },
    hideNewReactionModal() {
      this.newReactionModalVisible = false
    },
    onReactionSelect(reaction: Reaction) {
      this.current = reaction
    },
    async onOk() {
      this.error = false

      const model_id = this.$store.state.model?.id
      let res: AxiosResponse<Reaction> | undefined

      if (!this.current.id) res = await post<Reaction>('reactions', { ...this.current, model_id })
      else res = await patch<Reaction>(`reactions/${this.current.id}`, this.current)

      if (!res) {
        this.error = true
        return
      }

      this.hideNewReactionModal()

      this.reactions = await this.getReactions()
    },
    computeTableHeight() {
      this.tableHeight = blockHeightWoPadding(this.$refs.mainBlock)
    },
  },
  computed: mapState({
    filteredReactions() {
      return this.reactions.filter((e) => objStrSearchFilter(this.searchStr, e, { include: searchProps }))
    },
    emptyTableText() {
      return this.searchStr ? 'No matching reactions' : 'Create a reaction by using buttons below'
    },
  }),
}
