















import isEqualBy from '@/tools/is-equal-by'

import ReactionForm from '@/components/shared/entities/reaction-form.vue'

export default {
  name: 'reaction-properties',
  components: {
    'reaction-form': ReactionForm,
  },
  props: ['value', 'error'],
  data() {
    return {
      modified: this.value,
      original: this.value,
    }
  },
  mounted() {
    this.focusReactionForm()
  },
  methods: {
    focusReactionForm() {
      this.$nextTick(() => this.$refs.reactionForm.focus())
    },
    onInput() {
      this.$emit('input', this.modified)
    },
    apply() {
      this.$emit('apply')
    },
    reset() {
      this.modified = this.original
    },
  },
  computed: {
    reactionEdited() {
      return !isEqualBy(this.modified, this.original, ['name', 'definition', 'kr', 'kf', 'annotation'])
    },
  },
  watch: {
    value(val, oldVal) {
      if (val.id !== oldVal.id) {
        this.modified = val
        this.original = val
        this.focusNameInput()
      }
    },
  },
}
